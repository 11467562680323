import { createSlice } from '@reduxjs/toolkit';

const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    displayName: '',
    email: '',
    id: '',
  },
  reducers: {
    setUserData(state, action) {
      state.email = action.payload.email;
      state.displayName = action.payload.displayName;
      state.id = action.payload.id;
    }
  }
});

export const { setUserData } = userDataSlice.actions

export default userDataSlice.reducer