import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Layout, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { getUser,updateUser } from '../../apis/apis';
import ChatPageContent from '../../components/ChatPageContent/ChatPageContent';
import LeftMenuSlider from '../../components/LeftMenuSlider/LeftMenuSlider';
import { User } from '../../models/models';
import AcceptTermsPage from '../AcceptTermPage/AcceptTermsPage';
import ChatPageStyles from './ChatPageStyles';


export const ChatPage = () => {

  // THEME
  const styles = ChatPageStyles();

  // MSAL
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // USE STATES
  const [loading, setLoading] = useState(true);
  const [requiereAcceptTerms, setRequiereAcceptTerms] = useState(true);

  // USE EFFECTS
  useEffect(() => {
    if (isAuthenticated && accounts && accounts.length > 0) {
      handleGetUserData();
    }
  }, [isAuthenticated]);

  // FUNCTIONS
  async function handleGetUserData() {
    const userData: User | null = await getUser(instance, accounts[0]);
    if (!userData) {
      setLoading(false);
      setRequiereAcceptTerms(true);
      return;
    }
        
    if (userData.acceptedTerms) {
      setLoading(false);
      setRequiereAcceptTerms(false);
      //validate  lastLogin and update it if is more than 1 day
      const lastLogin = new Date(userData.lastLogin || '');
      const now = new Date();
      if (now.getTime() - lastLogin.getTime() > 86400000) {
        updateUser(instance, accounts[0], userData);
      }
      
    }
  }

  if (loading) {
    return (
      <Layout style={styles.centeredLayout}>
        <Spin />
      </Layout>
    );
  }

  if (requiereAcceptTerms) {
    return (
      <AcceptTermsPage onAccept={handleGetUserData} />
    );
  }


  return (
    <Layout style={styles.layout}>
      <LeftMenuSlider />
      <Layout>
        {/* <ChatPageHeader /> */}
        <ChatPageContent />
      </Layout>
    </Layout>
  );
}
