const ChatPageStyles = () => ({
    layout: {
        height: '100vh',
    },
    centeredLayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
});

export default ChatPageStyles;
