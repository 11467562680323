import { DeleteOutlined, LogoutOutlined, MessageOutlined, MoonOutlined, PlusCircleOutlined, SunOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { App, Button, Divider, Flex, Input, Layout, List, Space, Spin, Switch, theme, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { createChatRoom, deleteChatRoom, getChatRooms } from '../../apis/apis';
import { ChatRoom } from '../../models/models';
import { toggleTheme } from '../../redux/slices/themeSlice';
import { RootState } from '../../redux/store';
import { AppConfirmationDialog } from '../../utils/AppDialogs';
import LeftMenuSliderStyles from './LeftMenuSliderStyles';

const appName = process.env.REACT_APP_APP_NAME;
const { Sider } = Layout;
// TYPOGRAPHY
const { Text } = Typography;

const LeftMenuSlider: React.FC = () => {

  const navigate = useNavigate();

  // THEME
  const { token } = theme.useToken();
  const styles = LeftMenuSliderStyles(token);

  // MSAL
  const { instance, accounts, inProgress } = useMsal();

  // MESSAGES
  const { message, modal } = App.useApp();

  // REDUX
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  // USE STATES
  const [chatRooms, setChatRooms] = useState<ChatRoom[]>([]);
  const [selectedChatRoom, setSelectedChatRoom] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);




  // USE EFFECTS
  useEffect(() => {
    if (instance && accounts && accounts.length > 0) {
      getAllChatRooms();
    }

  }, [instance, accounts, inProgress]);

  // FUNCTIONS

  function userAccount() {
    if (accounts && accounts.length > 0) {
      return accounts[0];
    }
    return null;
  }

  function handleLogout() {
    AppConfirmationDialog(modal, 'Confirm Log Out?', 'Are you sure you want to log out?', async () => {
      if (instance) {
        await instance.logout().then(() => {
          navigate('/');
        });
      }
    });
  }

  function handleNewChat() {
    if (instance === null) {
      message.error('No active account! Please sign in.');
      return;
    }

    modal.confirm({
      title: 'New Chat',
      content: (
        <div>
          <Text>Write a name for the chat</Text>
          <Input id="chatName" />
        </div>
      ),
      async onOk() {
        const chatName = (document.getElementById('chatName') as HTMLInputElement).value;
        const newChat: ChatRoom = {
          id: uuidv4(),
          name: chatName,
          userId: userAccount()?.localAccountId || '',
          createdAt: null,
        };
        setLoading(true);
        try {
          const result = await createChatRoom(instance, userAccount(), newChat);
          if (result) {
            await getAllChatRooms();
            handleSelectChat(newChat);
          }
        } catch (error) {
          message.error('Error creating chat.');
        } finally {
          setLoading(false);
        }
      },
    });
  }

  async function getAllChatRooms() {
    if (instance === null) {
      message.error('No active account! Please sign in.');
      return;
    }

    setLoading(true);
    try {
      const chats = await getChatRooms(instance, userAccount());
      setChatRooms(chats);
    } catch (error) {
      message.error('Error getting chat rooms.');
    } finally {
      setLoading(false);
    }
  }

  function handleSelectChat(chat: ChatRoom) {
    setSelectedChatRoom(chat.id);
    dispatch({
      type: 'chatRoom/setChatRoom',
      payload: chat,
    });
  }

  function handleDeleteChat(chatRoom: ChatRoom) {

    if (instance === null) {
      message.error('No active account! Please sign in.');
      return;
    }

    modal.confirm({
      title: 'Delete Chat',
      content: 'Are you sure you want to delete this chat?',
      async onOk() {
        const result = await deleteChatRoom(instance, userAccount(), chatRoom.id);
        if (result) {
          await getAllChatRooms();

          if (chatRoom.id === selectedChatRoom) {
            //dispatch messageContent to clear messages
            const emptyChat: ChatRoom = {
              id: '',
              name: '',
              userId: '',
              createdAt: null,
            };
            dispatch({
              type: 'chatRoom/setChatRoom',
              payload: emptyChat,
            });
          }
          setSelectedChatRoom(null);
        }
      },
    });
  }

  return (
    <Spin spinning={loading} size="large">
      <Sider
        theme={isDarkMode ? 'dark' : 'light'}
        breakpoint="lg"
        collapsedWidth="0"
        style={styles.sider}
        width={250}
      >
        <div style={styles.centeredContainer}>
          <img width={80} src="/icon-transparent.png" alt="Logo" style={{ marginTop: '10pxx' }} />
        </div>
        <div style={styles.centeredContainer}>

          <h2>{appName}</h2>
        </div>
        <Flex justify={'center'} align={'center'}>
          <Button
            style={styles.button}
            type="primary"
            block
            icon={<PlusCircleOutlined />}
            onClick={handleNewChat}
          >
            New chat
          </Button>
        </Flex>
        <div style={{ ...styles.menuWrapper, overflowY: 'auto', height: '60vh' }}>
          <List
            itemLayout="horizontal"
            dataSource={chatRooms}
            renderItem={(chat: ChatRoom) => (
              <List.Item
                key={chat.id}
                onClick={() => handleSelectChat(chat)}
                style={{
                  ...styles.listItems,
                  background: selectedChatRoom === chat.id ? token.colorPrimaryBg : token.colorBgContainerDisabled,
                }}
                actions={[
                  <DeleteOutlined
                    key="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteChat(chat);
                    }}
                    style={{ color: 'red' }}
                  />
                ]}
              >
                <List.Item.Meta
                  avatar={<MessageOutlined />}
                  title={chat.name}
                />
              </List.Item>
            )}
          />
        </div>
        <Divider />
        <Flex justify={'center'} align={'center'}>
          <Space direction="vertical" size="middle">

            <Text strong>{userAccount()?.name}</Text>

            <Flex justify={'center'} align={'center'}>
              <Switch
                checkedChildren={<MoonOutlined />}
                unCheckedChildren={<SunOutlined />}
                checked={isDarkMode}
                onChange={() => dispatch(toggleTheme())}
              />
            </Flex>

            <Flex justify={'center'} align={'center'}>
              <Button
                type="text"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              >
                Log out
              </Button>
            </Flex>
          </Space>
        </Flex>

      </Sider>
    </Spin>
  );
};

export default LeftMenuSlider;
