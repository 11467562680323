import { CloseOutlined, CopyOutlined, DeleteOutlined, PaperClipOutlined, RedoOutlined, SendOutlined, StarFilled, StarOutlined, StopOutlined, UploadOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';
import { App, Badge, Button, Card, Col, Grid, Input, Layout, Row, Select, Spin, theme, Typography, Upload, Image } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { v4 as uuidv4 } from 'uuid';
import { ask, createChatMessage, getMessages, getUser, uploadChatAttachment } from '../../apis/apis';
import { aiModels, AskMessage, ChatMessage, User } from '../../models/models';
import { RootState } from '../../redux/store';
import ChatPageContentStyles from './ChatPageContentStyles';
import ReactMarkdown from 'react-markdown';

const { Text, Paragraph, Title } = Typography;
const { useBreakpoint } = Grid;
const ChatPageContent = () => {
    // THEME
    const { token } = theme.useToken();
    const styles = ChatPageContentStyles(token);
    const screens = useBreakpoint();

    // MESSAGES
    const { message, modal } = App.useApp();

    // MSAL
    const { instance, accounts } = useMsal();

    // REDUX
    const chatRoom = useSelector((state: RootState) => state.chatRoom);

    // REFS
    const messagesEndRef = useRef<HTMLDivElement>(null);

    // USE STATES
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [model, setModel] = useState('gpt-4o');
    const [isAsking, setIsAsking] = useState(false);
    const [askController, setAskController] = useState<AbortController>(new AbortController());
    const [attachmentUrl, setAttachmentUrl] = useState<string | null>(null); // Para guardar la URL del archivo subido
    const [isUploading, setIsUploading] = useState(false); // Para controlar el estado de carga del archivo
    //const [attachmentFile, setAtachmentFile] = useState<File | null>(null);
    const [curretUser, setCurrentUser] = useState<User | null>(null);

    // USE EFFECTS
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        getAllMessages();
    }, [chatRoom]);

    useEffect(() => {

        async function fetchUser() {
            const user = await getUser(instance,userAccount());
            setCurrentUser(user);
        }
        fetchUser();
    }, []);

    // FUNCTIONS
    const handleFileUpload = async (file: File) => {
        setIsUploading(true); // Iniciar el estado de carga
        try {
            const url = await uploadChatAttachment(instance, userAccount(), file, chatRoom.id); // Suponiendo que ya tienes la función para subir archivos

            if (url == null) {
                message.error('Error al subir el archivo');
                return;
            }

            console.log('url: ', url);

            setAttachmentUrl(url); // Guardar la URL del archivo subido
        } catch (error) {
            message.error('Error al subir el archivo.');
        } finally {
            setIsUploading(false); // Terminar el estado de carga
        }
    };

    const uploadProps = {
        beforeUpload: (file: File) => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

            if (!isJpgOrPng) {
                message.error('Solo puedes subir archivos JPG/PNG.');
                return false;  // Evitar la subida si no es JPG o PNG
            }
            handleFileUpload(file); // Llamar a la función de subida
            return false; // Evitar que el componente Upload haga la subida automáticamente
        },
        multiple: false, // Solo permite subir un archivo
    };


    function handleModelChange(value: string) {
        setModel(value);
    }

    function scrollToBottom() {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    function userAccount() {
        if (accounts && accounts.length > 0) {
            return accounts[0];
        }
        return null;
    }

    const getModelColor = (modelValue: string) => {
        const colors = [
            'orange',
            'green',
            'blue',
            'purple',
            'red',
            'cyan',
            'magenta',
            'lime',
            'volcano',
        ];

        const index = aiModels.findIndex((model) => model.value === modelValue);

        return colors[index % colors.length];
    };

    async function handleSendMessage() {
        if (!instance) {
            message.error('No active account! Please sign in.');
            return;
        }

        if (!chatRoom.id) {
            message.error('No chat room selected.');
            return;
        }

        const content = inputValue.trim();

        if (content) {
            const chatMessage: ChatMessage = {
                id: uuidv4(),
                userId: userAccount()?.localAccountId || '',
                chatRoomId: chatRoom.id,
                content: content,
                attachmentUrl: attachmentUrl,
                owner: 'user',
                model: '',
                createdAt: new Date().toISOString(),
            }

            await createChatMessage(instance, userAccount(), chatMessage);
            messages.push(chatMessage);
            setMessages([...messages]);
            setInputValue('');
            handleAsk(content);
        }
    }

    async function getAllMessages() {
        if (instance === null) {
            message.error('No active account! Please sign in.');
            return;
        }

        if (chatRoom === null) {
            message.error('No chat room selected.');
            return;
        }

        if (chatRoom.id === '') {
            setMessages([]);
            return;
        }

        setLoading(true);
        try {
            const chatMessages = await getMessages(instance, userAccount(), chatRoom.id);
            setMessages(chatMessages);
        } catch (error) {
            message.error('Error getting chat rooms.');
        } finally {
            setLoading(false);
        }
    }

    const handleAsk = async (userMessageContent: string) => {
        setLoading(true);
        setIsAsking(true);
        const controller = new AbortController();
        setAskController(controller);

        const askMessage: AskMessage = {
            id: uuidv4(),
            userId: userAccount()?.localAccountId || '',
            chatRoomId: chatRoom.id,
            content: userMessageContent,
            model: model,
            createdAt: new Date().toISOString(),
            attachmentUrl: attachmentUrl
        };

        const newMessage: ChatMessage = {
            id: uuidv4(),
            userId: userAccount()?.localAccountId || '',
            chatRoomId: chatRoom.id,
            content: '',
            attachmentUrl: attachmentUrl,
            owner: 'ai',
            model: model,
            createdAt: new Date().toISOString(),
        };

        setMessages([...messages, newMessage]);

        try {
            await ask(instance, userAccount(), askMessage, (content) => {
                newMessage.content += content;
                setMessages([...messages, newMessage]);
            }, controller.signal);

            await createChatMessage(instance, userAccount(), newMessage);
        } catch (error: any) {
            console.error('error.name: ', error.name);
            if (error.name !== 'AbortError') {
                console.error('Error asking question: ', error);
            } else {
                await createChatMessage(instance, userAccount(), newMessage);
            }
        } finally {
            setLoading(false);
            setIsAsking(false);
        }
    };

    const handleStopAsk = async () => {
        askController.abort();
        setIsAsking(false);
        setLoading(false);
    };

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        message.success('Code copied to clipboard');
    };

    const formatContent = (content: string) => {
        const codeRegex = /```[\s\S]*?```/g;
        const parts = content.split(codeRegex);

        return parts.map((part, index) => {
            const match = content.match(codeRegex);
            if (match && match[index]) {
                const codeBlock = match[index].replace(/```/g, '');
                return (
                    <div key={index}>
                        <Paragraph>{part}</Paragraph>
                        <div style={styles.codeHeader}>
                            <Button icon={<CopyOutlined />} size="small" onClick={() => handleCopy(codeBlock)}>
                                Copy
                            </Button>
                        </div>
                        <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
                            {codeBlock}
                        </SyntaxHighlighter>
                    </div>
                );
            } else {
                return part.split('\n').map((line, index) => (
                    <Paragraph key={index} style={styles.text}>
                        {line}
                    </Paragraph>
                ));
            }
        });
    };

    const renderMessageActions = (msg: ChatMessage) => {
        return (
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                <Button
                    icon={<CopyOutlined />}
                    onClick={() => handleCopy(msg.content)}
                    title="Copiar al portapapeles"
                    style={styles.iconButton}
                />
            </div>
        );
    };

    const titleLevel = screens.xs ? 5 : 3;

    return (
        chatRoom?.id === '' ? (
            <Layout style={styles.chatContainer}>
                <Row justify={'center'} align={'middle'} style={{ height: '100%' }}>
                    <Col>
                        <Title level={titleLevel}>Selecciona un chat para comenzar o agrega uno nuevo</Title>
                    </Col>
                </Row>
            </Layout>
        ) : (
            <Layout style={styles.chatContainer}>
                <Header style={styles.header}>
                    <Row justify={'space-between'}>
                        <Col>
                            <Text strong>{chatRoom.name}</Text>
                        </Col>
                        <Col>
                            <Select
                                style={{ width: 190 }}
                                value={model}
                                onChange={handleModelChange}
                                options={aiModels}
                                disabled={isAsking}
                            />
                        </Col>
                    </Row>
                </Header>
                <div style={styles.messagesContainer}>
                    {messages.map((msg, index) => (
                        <Row key={index} justify="center">
                            <Col xs={20} sm={20} md={16} lg={12}>
                                {msg.owner === 'ai' ? (
                                    <Badge.Ribbon
                                        text={msg.model}
                                        color={getModelColor(msg.model!)}
                                        placement="start"
                                    >
                                        <Card style={styles.message}>
                                            <ReactMarkdown>
                                                {msg.content}
                                            </ReactMarkdown>
                                            <div style={styles.messageMeta}>
                                                <Text type="secondary" style={styles.messageMetaText}>
                                                    {new Date(msg.createdAt!).toLocaleString()}
                                                </Text>
                                            </div>
                                            {renderMessageActions(msg)}
                                        </Card>
                                    </Badge.Ribbon>
                                ) : (
                                    <Badge.Ribbon text={curretUser?.name} color={token.colorPrimary}>
                                        <Card style={{
                                            ...styles.message,
                                        }}>
                                            <ReactMarkdown>
                                                {msg.content}
                                            </ReactMarkdown>
                                            {msg.attachmentUrl && (
                                                <Image
                                                    width={200}
                                                    src={msg.attachmentUrl}
                                                    alt="Uploaded file"
                                                />
                                            )}

                                            <div style={styles.messageMeta}>
                                                <Text type="secondary" style={styles.messageMetaText}>
                                                    {new Date(msg.createdAt!).toLocaleString()}
                                                </Text>
                                            </div>
                                        </Card>
                                    </Badge.Ribbon>
                                )}
                            </Col>
                        </Row>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
                <Spin spinning={loading && !isAsking} size="large">
                    {
                        attachmentUrl != null && (
                            <Row justify={'center'} align={'middle'} style={{ marginBottom: '10px' }}>
                                <Col xs={18} sm={18} md={14} lg={14} xl={14}>
                                    {attachmentUrl && (
                                        <Badge
                                            count={<PaperClipOutlined style={{ fontSize: '24px' }} />} // Puedes ajustar el tamaño aquí
                                        //text={attachmentUrl.split('/').reverse()[0]}
                                        />

                                    )}
                                </Col>
                            </Row>
                        )
                    }

                    <Row justify={'center'} align={'middle'} gutter={5}>
                        {
                            model && model.includes('gpt') && (
                                <>
                                    <Col>
                                        <Button disabled={attachmentUrl === null}
                                            icon={<CloseOutlined />}
                                            onClick={() => setAttachmentUrl(null)} // Al hacer clic, eliminar el attachment
                                        />
                                    </Col>
                                    <Col>
                                        <Upload
                                            accept='.png,.jpg'
                                            disabled={attachmentUrl != null} {...uploadProps} showUploadList={false}>
                                            <Button disabled={attachmentUrl != null} icon={isUploading ? <Spin /> : <UploadOutlined />} />
                                        </Upload>
                                    </Col>
                                </>
                            )
                        }


                        <Col xs={16} sm={16} md={12} lg={12} xl={12}>



                            <Input.TextArea
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();  // Prevenir el comportamiento predeterminado de agregar una nueva línea
                                        handleSendMessage();  // Llamar a la función para enviar el mensaje
                                    }
                                }}
                                onPressEnter={(e) => {
                                    if (e.shiftKey) {
                                        return;  // Permitir el salto de línea si se presiona Shift + Enter
                                    }
                                    e.preventDefault();  // Prevenir el salto de línea si no está presionada la tecla Shift
                                }}
                                placeholder="Type a message"
                                style={styles.input}
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                disabled={isAsking}
                            />
                        </Col>


                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            {isAsking ? (
                                <Button type="primary" icon={<StopOutlined />} onClick={handleStopAsk} style={styles.sendButton}>
                                    Stop
                                </Button>
                            ) : (
                                <Button type="primary" icon={<SendOutlined />} onClick={handleSendMessage} style={styles.sendButton}>
                                    Send
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Spin>
            </Layout>
        )
    );
}

export default ChatPageContent;
