export interface AskMessage {
  id: string;
  userId: string;
  chatRoomId: string;
  content: string;
  model: string; // 'OpenAI' | 'Ollama' | etc;
  createdAt: string | null;
  attachmentUrl: string | null;
}

export interface ChatMessage {
  id: string;
  userId: string;
  chatRoomId: string;
  content: string;
  owner: 'user' | 'ai';
  model: string; // 'OpenAI' | 'Ollama' | etc;
  createdAt: string | null;
  attachmentUrl: string | null;
}

export interface ChatRoom {
  id: string;
  name: string;
  userId: string;
  createdAt: string | null;
}


export const aiModels = [
  // { value: 'qwen2:0.5b', label: 'qwen2' },
  // { value: 'llama3.1:8b', label: 'llama3.1 (very slow)' },
  // { value: 'gemma2:2b', label: 'gemma2 (slow)' },
  // { value: 'gpt-4o-mini', label: 'gpt-4o-mini (cheap)' },
  // { value: 'gpt-4-turbo', label: 'gpt-4-turbo (normal)' },
  // { value: 'gpt-3.5-turbo', label: 'gpt-3.5-turbo (cheapest)' },
  { value: 'gpt-4o', label: 'gpt-4o' }
];

export interface User {
  id: string;
  name: string;
  email: string;
  acceptedTerms: boolean;
  createdAt?: string;
  lastLogin?: string;
}

