import { createSlice } from '@reduxjs/toolkit';
import { ChatRoom } from '../../models/models';

const initialState: ChatRoom = {
  id: '',
  name: '',
  userId: '',
  createdAt: '',
};

const chatRoomSlice = createSlice({
  name: 'chatRoom',
  initialState: initialState,
  reducers: {
    setChatRoom(state, action) {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.userId = action.payload.userId;
      state.createdAt = action.payload.createdAt;
    }
  }
});

export const { setChatRoom } = chatRoomSlice.actions

export default chatRoomSlice.reducer