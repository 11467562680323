import { useIsAuthenticated } from '@azure/msal-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChatPage } from '../pages/ChatPage/ChatPage';
import LoginPage from '../pages/LoginPage/LoginPage';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return children;
};

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
            path="/chat"
            element={
                <ProtectedRoute>
                    <ChatPage />
                </ProtectedRoute>
            }
        />
    </Routes>
);

export default AppRoutes;
