import { GlobalToken } from 'antd';

const ChatPageContentStyles = (token: GlobalToken) => ({


    header: {
        backgroundColor: 'transparent',
        borderRadius: '20px',
        marginBottom: '16px',
    },
    text: {
        //color: token.colorTextSecondary,
        fontSize: '16px',
    },
    chatContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        padding: '16px',
    },
    messagesContainer: {
        flex: 1,
        overflowY: 'auto' as const,
        marginBottom: '16px',
        backgroundColor: token.colorBgContainer,
        height: '70vh',
        border: `1px solid ${token.colorBorder}`,
        borderRadius: '20px',
    },
    message: {
        padding: '8px',
        margin: '8px 0',
        backgroundColor: token.colorBgContainerDisabled,
    },
    messageMeta: {
        marginTop: '4px',
    },
    messageMetaText: {
        fontSize: '12px',
        //color: token.colorTextHeading,
    },
    inputRow: {
        //justifyContent: 'center',
        //alignItems: 'center',
        //padding: '8px 0',
    },
    input: {
        flex: 1,
        marginRight: '8px',
    },
    sendButton: {
        marginLeft: '8px',
    },
    codeHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '4px',
    },
    iconButton: {
        backgroundColor: 'transparent', // Fondo transparente
        border: 'none',                // Sin borde
        boxShadow: 'none',             // Sin sombra de botón
        padding: 0,                    // Sin relleno extra alrededor del icono
    }
});

export default ChatPageContentStyles;
