import { GlobalToken } from 'antd';

const AcceptTermsPageStyles = (token: GlobalToken) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: token.colorBgContainerDisabled,
    },
    card: {
        borderRadius: '20px',
        textAlign: 'center' as const,
    },
    logo: {
        width: 100,
        height: 100,
    },
    button: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkboxLabel: {
        fontWeight: 'bold',
    },
    link: {
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
});

export default AcceptTermsPageStyles;
