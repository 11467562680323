import { HookAPI } from 'antd/es/modal/useModal';

const AppConfirmationDialog = (modal: HookAPI, title: string, message: string, onConfirm: () => void, onCancel?: () => void) => {
  modal.confirm({
    title: title,
    content: message,
    centered: true,
    onOk() {
      onConfirm();
    },
    onCancel() {
      if (onCancel) {
        onCancel();
      }
    },
  });
};

const AppAlertDialog = (modal: HookAPI, title: string, message: string, onConfirm: () => void) => {
  modal.info({
    title: title,
    content: message,
    centered: true,
    onOk() {
      onConfirm();
    },
  });
};
export { AppAlertDialog, AppConfirmationDialog };

