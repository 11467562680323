import { GlobalToken } from 'antd';

const LeftMenuSliderStyles = (token: GlobalToken) => ({
  sider: {
    background: token.colorBgContainer,
    minHeight: '100vh',
  },
  logo: {
    textAlign: 'center' as const,
    padding: '20px 0',
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //height: '100vh',
    //backgroundColor: token.colorBgContainerDisabled,
  },
  menuWrapper: {
    padding: '0 16px',
  },
  button: {
    margin: '0 16px 12px 16px',
  },
  listItems: {
    cursor: 'pointer',
    padding: '10px',
    borderBottom: '1px solid #ddd',
    borderRadius: '5px',
  },
  menuContainer: {
    background: token.colorBgContainerDisabled,
    borderRadius: '12px',
    padding: '16px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    overflowY: 'auto' as const,
    height: '60vh'
  }
});

export default LeftMenuSliderStyles;
