import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Card, Layout, theme, Typography } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../../config/authConfig';
import LoginPageStyles from './LoginPageStyles';

const { Text, Title } = Typography;

const appName = process.env.REACT_APP_APP_NAME;

const LoginPage = () => {
  // NAVIGATION
  const navigate = useNavigate();

  // THEME
  const { token } = theme.useToken();
  const styles = LoginPageStyles(token);

  // MSAL
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // USE EFFECTS
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/chat');
    }
  }, [isAuthenticated]);

  // FUNCTIONS
  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch(e => {
      console.error(e);
    });
  };

  // RENDER
  return (
    <Layout style={styles.content}>
      <Card style={styles.card}>
        <img src="/icon-transparent.png" alt="Logo" style={styles.logo} />
        <Title level={4}>{appName}</Title>
        <Text>Please log in with your Microsoft Peralada account</Text>
        <Button type="primary" onClick={handleLogin} style={styles.button}>LOGIN WITH AZURE AD</Button>
      </Card>
    </Layout>
  );
};

export default LoginPage;
