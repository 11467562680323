
const clientId = process.env.REACT_APP_AZURE_AD_CLIENT_ID;
const tenantId = process.env.REACT_APP_AZURE_AD_TENANT_ID;
const redirectUri = process.env.REACT_APP_AZURE_AD_REDIRECT_URI;

if (!clientId || !tenantId || !redirectUri) {
  throw new Error('Azure AD environment variables are not set');
}

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: ["User.Read"]
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me" //e.g. https://graph.microsoft.com/v1.0/me
};
