import { configureStore } from '@reduxjs/toolkit';
import chatRoomReducer from './slices/chatRoomSlice';
import themeReducer from './slices/themeSlice';
import userDataReducer from './slices/userDataSlice';

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    userData: userDataReducer,
    chatRoom: chatRoomReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['msal/setMsalInstance', 'msal/setMsalAccount'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload'],
        // Ignore these paths in the state
        ignoredPaths: ['msal.instance', 'msal.account'],
      },
    }),
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch