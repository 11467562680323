import { GlobalToken } from 'antd';

const LoginPageStyles = (token: GlobalToken) => ({
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: token.colorBgContainerDisabled,
    },
    card: {
        width: 400,
        borderRadius: '20px',
        textAlign: 'center' as const,
    },
    logo: {
        width: 100,
        //height: 100,
    },
    button: {
        marginBottom: '20px',
        width: '100%',
        marginTop: '20px',
    },
});

export default LoginPageStyles;
