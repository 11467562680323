import { useMsal } from '@azure/msal-react';
import { Button, Card, Checkbox, Col, Layout, Row, theme, Typography } from 'antd';
import { useState } from 'react';
import { createUser } from '../../apis/apis';
import { User } from '../../models/models';
import AcceptTermsPageStyles from './AcceptTermPageStyles';

const { Title, Paragraph } = Typography;

const AcceptTermsPage = ({ onAccept }: { onAccept: () => void }) => {

    // THEME
    const { token } = theme.useToken();
    const styles = AcceptTermsPageStyles(token);

    // MSAL
    const { instance, accounts } = useMsal();

    // USE STATES
    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCheckboxChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setAccepted(e.target.checked);
    };

    // FUNCTIONS
    async function handleAcceptTerms() {
        const user: User = {
            id: accounts[0].localAccountId,
            name: accounts[0].name || '',
            email: accounts[0].username,
            acceptedTerms: true,
        };
        setLoading(true);
        const result = await createUser(instance, accounts[0], user);
        if (!result) {
            console.error('Error creating user');
            return;
        }
        onAccept();
    };

    // RENDER
    return (
        <Layout style={styles.content}>
            <Row justify="center" style={{ width: '100%' }}>
                <Col xs={22} sm={20} md={16} lg={14} xl={12}>
                    <Card style={styles.card}>
                        <Title level={4}>{'Términos y Condiciones de Uso'}</Title>
                        <Paragraph>
                            Para usar nuestro ChatGPT Corporativo, necesitamos que aceptes nuestra Política de Inteligencia Artificial.
                            Puedes encontrarla completa en nuestra intranet. Te contamos cómo usamos la Inteligencia Artificial para
                            mejorar tu experiencia y proteger tu privacidad.
                        </Paragraph>
                        <Paragraph>
                            ¿Aceptas nuestra Política de Inteligencia Artificial?
                        </Paragraph>
                        <Checkbox onChange={handleCheckboxChange}>
                            <span style={styles.checkboxLabel}>
                                Sí, he leído, entendido y acepto la Política de Inteligencia Artificial (
                                <a href="https://aichatbotcusto.com/terms.html" target="_blank" rel="noopener noreferrer" style={styles.link}>
                                    Política de IA
                                </a>
                                ).
                            </span>
                        </Checkbox>
                        <Button
                            type="primary"
                            onClick={handleAcceptTerms}
                            style={styles.button}
                            disabled={!accepted}
                            loading={loading}
                        >
                            CONTINUAR AL CHAT
                        </Button>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default AcceptTermsPage;
