import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { AskMessage, ChatMessage, ChatRoom, User } from '../models/models';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAccessToken = async (instance: IPublicClientApplication, account: AccountInfo | null): Promise<string | null> => {
  if (!account) {
    console.error('No active account! Please sign in.');
    return null;
  }

  try {
    const accessTokenResponse = await instance.acquireTokenSilent({
      scopes: ['User.Read'],
      account: account,
    });

    const accessToken = accessTokenResponse.accessToken;
    return accessToken;
  } catch (error) {
    console.error('Error acquiring token silently', error);
    return null;
  }
};


export const getUser = async (instance: IPublicClientApplication, account: AccountInfo | null): Promise<User | null> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    const response = await axios.get(`${API_BASE_URL}/getUser/${account?.localAccountId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    return response.data as User;
  } catch (error) {
    console.error('Error getting user: ', error);
    return null;
  }
};

export const createUser = async (instance: IPublicClientApplication, account: AccountInfo | null, user:User): Promise<boolean> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    await axios.post(`${API_BASE_URL}/createUser`, user, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return true;
  } catch (error) {
    console.error('Error creating user: ', error);
    return false;
  }
};

//updateUser
export const updateUser = async (instance: IPublicClientApplication, account: AccountInfo | null, user:User): Promise<boolean> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    await axios.put(`${API_BASE_URL}/updateUser`, user, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return true;
  } catch (error) {
    console.error('Error updating user: ', error);
    return false;
  }
};

export const createChatRoom = async (instance: IPublicClientApplication, account: AccountInfo | null, chatRoom: ChatRoom): Promise<boolean> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    await axios.post(`${API_BASE_URL}/createChatRoom`, chatRoom, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return true;
  } catch (error) {
    console.error('Error creating chat: ', error);
    return false;
  }
};

export const getChatRooms = async (instance: IPublicClientApplication, account: AccountInfo | null): Promise<ChatRoom[]> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    const response = await axios.get(`${API_BASE_URL}/getChatRooms/${account?.localAccountId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    return response.data as ChatRoom[];
  } catch (error) {
    console.error('Error getting chat rooms: ', error);
    return [];
  }
};

export const deleteChatRoom = async (instance: IPublicClientApplication, account: AccountInfo | null, chatRoomId: string): Promise<boolean> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    await axios.delete(`${API_BASE_URL}/deleteChatRoom/${chatRoomId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    return true;
  } catch (error) {
    console.error('Error deleting chat: ', error);
    return false;
  }
};

export const createChatMessage = async (instance: IPublicClientApplication, account: AccountInfo | null, chatMessage: ChatMessage): Promise<boolean> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    await axios.post(`${API_BASE_URL}/createMessage`, chatMessage, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return true;
  } catch (error) {
    console.error('Error creating chat: ', error);
    return false;
  }
};

//getMessages
export const getMessages = async (instance: IPublicClientApplication, account: AccountInfo | null, chatRoomId: string): Promise<ChatMessage[]> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  try {
    const response = await axios.get(`${API_BASE_URL}/getMessages/${account?.localAccountId}/${chatRoomId}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    return response.data as ChatMessage[];
  } catch (error) {
    console.error('Error getting messages: ', error);
    return [];
  }
};

export const uploadChatAttachment = async (instance: IPublicClientApplication, account: AccountInfo | null, file: File, chatRoomId : string): Promise<string | null> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

    const fileTypeData = file.type.split('/').map((text) => text.trim());
    const fileExtension = fileTypeData[1];
    console.log('file type: ', fileExtension);

  try {
    const formData = new FormData();
    formData.append('file', file);  
    formData.append('fileExtension', fileExtension);

    // Hacer la solicitud POST para subir el archivo
    const response = await axios.post(`${API_BASE_URL}/upload_file`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',  // Usar multipart/form-data para enviar archivos
      },
    });

    return response.data.url;  // Devolver la URL del archivo subido
  } catch (error) {
    console.error('Error uploading file: ', error);
    return null;
  }
};

// const fileToBase64 = (file: File): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       const base64String = (reader.result as string).split(',')[1];  // Extraer solo la parte base64
//       resolve(base64String);
//     };
//     reader.onerror = (error) => reject(error);
//   });
// };


export const ask = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  askMessage: AskMessage,
  onStream: (content: string) => void,
  signal: AbortSignal): Promise<void> => {
  const accessToken = await getAccessToken(instance, account);
  if (!accessToken) {
    throw new Error('Could not get access token, login again.');
  }

  const response = await fetch(`${API_BASE_URL}/ask`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(askMessage),
    signal
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const reader = response.body?.getReader();
  const decoder = new TextDecoder();
  let done = false;

  while (!done) {
    if (signal.aborted) {
      break;
    }

    const { value, done: doneReading } = await reader?.read() ?? { value: new Uint8Array(), done: true };
    done = doneReading;
    const chunk = decoder.decode(value, { stream: true });
    onStream(chunk);
  }
};
